<template>
    <div class="my-0 py-0" style="background: var(--v-component-base) !important">
        <v-card flat>
            <v-card-text>
                <v-row align="center" justify="end">
                    <v-spacer></v-spacer>
                    <v-chip 
                    @click="getSailedVesselVoyages" 
                    :outlined="!filterSearch" 
                    class="mx-1" color="danger"  
                    :close="filterSearch" 
                    @click:close="getVesselVoyages">
                        <v-icon left>warning</v-icon>
                        Sailed Shipment Files
                    </v-chip>
                    <v-col cols="12" sm="6" md="4">
                        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable
                            placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table class="mt-3" dense disable-pagination fixed-header hide-default-footer :loading="loading"
                    :items="vessels.data" :headers="headers" height="66vh" :calculate-widths="true" style="cursor: pointer">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn-toggle borderless group dense tile>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn :disabled="true" v-on="on" icon small color="primary" @click="editVessel(item)">
                                        <v-icon color="primary">edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit Vessel</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon small color="cyan" @click="viewSchedules(item)">
                                        <v-icon color="cyan">event</v-icon>
                                    </v-btn>
                                </template>
                                <span>View Schedules</span>
                            </v-tooltip>
                        </v-btn-toggle>
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                        <v-chip small outlined style="border: none">
                            {{ item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.callSign`]="{ item }">
                        {{ item.callSign }}
                    </template>
                    <template v-slot:[`item.countryCode`]="{ item }">
                        <v-avatar size="32" class="mr-1" v-if="item.countryCode">
                            <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.countryCode.toLowerCase()}.svg`">
                            </v-img>
                        </v-avatar>
                    </template>
                </v-data-table>
                <v-col cols="12" class="text-center">
                    <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                        :page-size.sync="params.limit" :page-sizes="[18, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile
                            ? 'sizes, prev, pager, next, jumper, total'
                            : 'prev, pager, next'
                            " :total="vessels.total">
                    </el-pagination>
                </v-col>
            </v-card-text>
        </v-card>

        <!-- Dialogs -->
        <v-dialog v-model="voyageDialog" persistent max-width="40vw">
            <v-card :loading="savingVoyage">
                <v-toolbar flat>
                    <v-icon color="primary">
                        sailing
                    </v-icon>
                    <v-card-title v-if="selectedVessel">
                        {{ selectedVessel.name }}
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon text @click="voyageDialog = false, selectedVessel = null"> <v-icon>close</v-icon> </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-list v-if="voyages.length > 0">
                        <v-divider></v-divider>
                        <div v-for="voyage in voyages" :key="voyage.id">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-2">
                                        <v-toolbar flat>
                                            <v-chip outlined class="mr-3">
                                                <v-avatar size="20" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${voyage.pol.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                                {{ voyage.pol }}
                                            </v-chip>
                                            <v-chip outlined class="mr-3">
                                                <v-icon color="blue" left> input </v-icon>
                                                {{ voyage.importVoyage }}
                                            </v-chip>
                                            <v-chip outlined class="mr-3">
                                                <v-icon color="orange" left> output </v-icon>
                                                {{ voyage.exportVoyage }}
                                            </v-chip>
                                            <v-spacer></v-spacer>
                                            <v-btn icon text color="secondary" @click="saveVoyage(voyage)">
                                                <v-icon>save</v-icon> </v-btn>
                                        </v-toolbar>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="my-2">
                                        <v-row>
                                            <v-col>
                                                <v-list-item>
                                                    <v-menu :close-on-content-click="true" transition="scale-transition"
                                                        offset-y min-width="auto">
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="text-left">
                                                                <v-list-item-action>
                                                                    <v-icon
                                                                        :color="voyage.etd ? 'success' : 'secondary'">today</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-if="voyage.etd">
                                                                        {{ formatDate(voyage.etd) }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-title v-else> - </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        Estimated Departure
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                        <v-date-picker v-model="voyage.etd" no-title scrollable>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-menu :close-on-content-click="true" transition="scale-transition"
                                                        offset-y min-width="auto">
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="text-left">
                                                                <v-list-item-action>
                                                                    <v-icon
                                                                        :color="voyage.atd ? 'success' : 'secondary'">today</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-if="voyage.atd">
                                                                        {{ formatDate(voyage.atd) }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-title v-else> - </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        Actual Departure
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                        <v-date-picker v-model="voyage.atd" no-title scrollable>
                                                        </v-date-picker>
                                                    </v-menu>

                                                </v-list-item>
                                            </v-col>
                                            <v-col>
                                                <v-list-item>
                                                    <v-menu :close-on-content-click="true" transition="scale-transition"
                                                        offset-y min-width="auto">
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="text-left">
                                                                <v-list-item-action>
                                                                    <v-icon
                                                                        :color="voyage.eta ? 'success' : 'secondary'">today</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-if="voyage.eta">
                                                                        {{ formatDate(voyage.eta) }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-title v-else> - </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        Estimated Arrival
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                        <v-date-picker v-model="voyage.eta" no-title scrollable>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-menu :close-on-content-click="true" transition="scale-transition"
                                                        offset-y min-width="auto">
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="text-left">
                                                                <v-list-item-action>
                                                                    <v-icon
                                                                        :color="voyage.ata ? 'success' : 'secondary'">today</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-if="voyage.ata">
                                                                        {{ formatDate(voyage.ata) }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-title v-else> - </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        Actual Arrival
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                        <v-date-picker v-model="voyage.ata" no-title scrollable>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-list-item>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </div>
                    </v-list>
                    <div v-else>
                        <span>No voyages for this vessel.</span>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
    components: {

    },
    data: () => ({
        page: 1,
        loading: false,
        filterSearch: false,
        searchTimer: null,
        params: {
            limit: 18,
            offset: 0,
            search: null,
            filter: { isActive: [true] },
        },
        vessels: {
            total: 0,
            data: []
        },
        headers: [
            {
                text: 'Action',
                value: 'action',
                align: 'center'
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
            },
            {
                text: 'Call Sign',
                value: 'callSign',
                align: 'center',
            },
            {
                text: 'Country',
                value: 'countryCode',
                sortable: false,
                align: 'center'
            },
            {
                text: 'IMO Number',
                value: 'imoNumber',
                align: 'center',
            },
            {
                text: 'MMSI Number',
                value: 'mmsiNumber',
                align: 'center',
            },
            {
                text: 'Vessel Type',
                value: 'type',
                align: 'center'
            },
            {
                text: 'Source',
                value: 'source'
            }
        ],
        selectedVessel: null,
        voyageDialog: false,
        voyages: [],
        savingVoyage: false
    }),
    mounted() {
        this.getVesselVoyages()
    },
    created() {

    },
    computed: {

    },
    watch: {
        'params.search': {
            async handler() {
                this.page = 1;
                await this.getVesselVoyages()
            }
        },
    },
    methods: {
        async getVesselVoyages() {
            this.filterSearch = false
            if (this.searchTimer) { clearTimeout(this.searchTimer) }
            this.loading = true
            this.searchTimer = setTimeout(async () => {
                this.vessels = await this.$API.getVesselVoyages({ params: this.params });
                this.loading = false
            }, 500)
        },
        async getSailedVesselVoyages() {
            this.filterSearch = true
            if (this.searchTimer) { clearTimeout(this.searchTimer) }
            this.loading = true
            this.searchTimer = setTimeout(async () => {
                this.vessels = await this.$API.getSailedVesselVoyages();
                this.loading = false
            }, 500)
        },
        async saveVoyage(voyage) {
            this.savingVoyage = true
            try {
                await this.$API.saveVesselVoyage(voyage)
                this.$message({
                    type: 'success',
                    message: 'Vessel Voyage Update!'
                })
            } catch (e) {
                this.$message({
                    type: 'error',
                    message: 'There was an error trying to update Vessel Voyage.'
                })
            }
            this.savingVoyage = false
        },
        viewSchedules(item) {
            this.selectedVessel = item
            this.voyages = item.rmsVesselVoyages
            this.voyageDialog = true
        },
        editVessel(item) {
            console.log(item);
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy");
            }
            return result;
        },
        formatDateTime(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
            }
            return result;
        },
        setTextColor(date) {
            if (date) {
                return 'blue'
            } else {
                return 'orange'
            }
        }
    },
};
</script>
  
<style scoped>
.scroll {
    overflow-y: scroll;
}
</style>